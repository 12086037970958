<template>
    <div>
        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
            <div v-if="!isLoadDetail">
                <p>Sender : {{ submissionDetail.user.name }}</p>
                <p v-if="submissionDetail.score">Score : {{ submissionDetail.score }}</p>
                <p v-else>Score : -</p>
                <p v-if="submissionDetail.status == 1"> Status: <span class="p-1 badge badge-success ml-2">Done</span></p>
                <p v-else-if="compareDatesGreater(submissionDetail.assignments.due_date)"> Status: <span class="p-1 badge badge-warning text-white">On Track</span></p>
                <p v-else> Status: <span class="p-1 badge badge-warning text-white">Missing</span></p>
            </div>
            <div v-else>
                <div class="card-body">
                    <div class="col-12 text-center py-3">
                        <p> Loading submission detail </p>
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isLoadAnswer" class="mt-3 card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">  
            <div class="card-body">
                <div class="row">
                    <div class="col-12 mb-2">
                        <h4>Questions</h4>
                    </div>
                    <div class="col-12">
                        <button v-for="(item, i) in answers" :disabled="disButton" :key="i" @click="setAnswer(i)" :class="{'btn-success' : item.score != null && answer == i, 'btn-outline-success' : item.score != null && answer != i, 'btn-outline-danger' : item.score == null && answer != i, 'btn-danger' : item.score == null && answer == i}" class="btn w50 h50 col mr-2 mb-2 ">{{i + 1}}</button>
                    </div>
                </div>
            </div>
            <div class="row" v-for="(item, index) in answers" :key="index">
                <div class="col-12" v-show="answer == index">
                    <div class="card-body p-0">
                        <div :id="'accordion1-'+index" class="accordion mb-0">
                            <div class="card shadow-xss mb-0">
                                <div class="card-header border-radius-none bg-current" :id="'heading1-'+index">
                                    <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link text-white collapsed" data-toggle="collapse" :data-target="'#collapse1-'+index" aria-expanded="false" :aria-controls="'collapse1-'+index">Question - {{index + 1}}</button></h5>
                                </div>
                                <div :id="'collapse1-'+index" class="collapse" :aria-labelledby="'heading1'+index" :data-parent="'#accordion1-'+index">
                                    <div class="card-body text-center" v-if="item.quest.quest_type == 1">
                                        <video class="w-50 h-auto" controls>
                                            <source :src=" item.quest.path" type="video/mp4">
                                        </video>
                                    </div>
                                    <div class="card-body" v-if="item.quest.quest_type == 2">
                                        <audio class="w-100" controls>
                                            <source :src=" item.quest.path" type="audio/mpeg">
                                        </audio>
                                    </div>
                                    <div class="card-body ql-editor">
                                        <div v-html="item.quest.question.replaceAll('[BLANK]', '[_____]')"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0" v-if="item.quest.submission_type != 7">
                        <div :id="'accordion2-'+index" class="accordion mb-0">
                            <div class="card shadow-xss mb-0">
                                <div class="card-header bg-greylight theme-dark-bg" :id="'heading-2'+index">
                                    <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse2-'+index" aria-expanded="false" :aria-controls="'collapse2-'+index">Answer</button></h5>
                                </div>
                                <div :id="'collapse2-'+index" class="collapse" :aria-labelledby="'heading2-'+index" :data-parent="'#accordion2-'+index">                
                                    <div v-if="item.quest.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                        <a :href=" item.answer" target="_blank" class="btn btn-success"><i class="fas fa-file mr-2"></i>File Answer</a>
                                    </div>
                                    <div v-if="item.quest.submission_type == 2" style="width: 100wh" class="card-body ql-editor p-3 border-top bg-lightgrey">
                                        <div v-html="item.answer"></div>
                                    </div>
                                    <div v-if="item.quest.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                        <table class="table m-0">
                                            <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                <td width="5%" v-if="opt.id == item.answer && item.quest.answer == item.answer" class="bg-success text-white"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                <td width="5%" v-else-if="opt.id == item.answer && item.quest.answer != item.answer" class="bg-danger"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                <td width="5%" v-else class="bg-grey text-success"><i v-if="item.quest.answer == opt.id" class="fas fa-check"></i></td>
                                                <td>{{opt.option}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div v-if="item.quest.submission_type == 4" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                        <a href="javascript:void(0)" :class="{'btn-success' : item.answer == '1', 'btn-outline-success' : item.answer != '1'}" class="btn btn-lg mr-2">True<i class="fas ml-2" :class="{'fa-check': '1' == item.quest.answer, 'fa-times': '1' != item.quest.answer}"></i></a>
                                        <a href="javascript:void(0)" :class="{'btn-danger' : item.answer == '0', 'btn-outline-danger' : item.answer != '0'}" class="btn btn-lg">False<i class="fas ml-2" :class="{'fa-check': '0' == item.quest.answer, 'fa-times': '0' != item.quest.answer}"></i></a>
                                    </div>
                                    <div v-if="item.quest.submission_type == 5" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                        <table class="table m-0">
                                            <tr v-for="(opt, j) in (item.answer?.split('*##*') || [])" :key="j">
                                                <td style="width: 1%;" class="bg-grey"><b>[BLANK]-{{j}}</b></td>
                                                <td>{{item?.quest?.answer ? item.quest.answer?.split('*##*')[j] : '-'}}</td>
                                                <td class="bg-grey">{{opt}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div v-if="item.quest.submission_type == 6" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                        <table class="table m-0">
                                            <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                <td class="bg-grey">{{opt.option}} ({{item?.quest?.answer ? item.quest.answer.split('*##*')[j] : '-'}})</td>
                                                <td>{{item.answer ? item.answer?.split('*##*')[j] : '-'}}</td>
                                                <td width="1%"><i class="fas ml-2" :class="{
                                                    'fa-check text-success': item.answer?.split('*##*')[j] === item.quest?.answer?.split('*##*')[j],
                                                    'fa-times text-danger': item.answer?.split('*##*')[j] !== item.quest?.answer?.split('*##*')[j]
                                                }"></i></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div v-if="item.quest.submission_type == 7" style="width: 100wh" class="card-body ql-editor border-top bg-lightgrey">
                                        <div v-html="item.answer"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0" v-if="item.quest.submission_type != 7">
                        <div :id="'accordion3-'+index" class="accordion mb-0">
                            <div class="card shadow-xss mb-0">
                                <div class="card-header bg-greylight theme-dark-bg" :id="'heading-3'+index">
                                    <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse3-'+index" aria-expanded="false" :aria-controls="'collapse3-'+index">Feedback</button></h5>
                                </div>
                                <div :id="'collapse3-'+index" class="collapse" :aria-labelledby="'heading3-'+index" :data-parent="'#accordion3-'+index">                
                                    <div class="card-body">
                                        <form @submit.prevent="postFeedback()" class="row">
                                            <div class="col-12">
                                                <div class="card border">
                                                    <div class="card-header bg-current p-3">
                                                        <h5 class="fw-600 text-white">Rubric : {{item.rubrics.name}}</h5>
                                                    </div>
                                                    <div class="card-body py-0 px-1">
                                                        <table class="table mb-0 w-100">
                                                            <tbody>
                                                                <tr v-for="(crt, i) in item.rubrics.criteria" :key="i" class="p-auto">
                                                                    <td width="80%" valign="middle" class="fw-600">
                                                                        <h2>
                                                                            {{crt.name}}
                                                                        </h2>
                                                                        <p>{{ crt.description }}</p>
                                                                    </td>
                                                                    <td width="10%" valign="middle" class="w100 h-75 text-right">
                                                                        <input v-model="crt.submit_score.id" type="hidden" >
                                                                        <input v-model="crt.submit_score.score" min="0" :max="crt.score" class="border-bottom form-control-sm w50" type="number" required>
                                                                    </td>
                                                                    <td width="10%"><h1> / {{crt.score}}</h1></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12">
                                                <label for="">Message</label>
                                                <vue-editor v-model="item.message" />
                                            </div>
                                            <div class="form-group col-12">
                                                <label for="">Attachment</label><br>
                                                <file-upload
                                                :input-id="setItemRef(index)"
                                                class="btn btn-current mr-1"
                                                @input="updateFile"
                                                @input-file="inputFile"
                                                :custom-action="fileAction"
                                                :ref="setItemRef(index)" required>
                                                <i class="fa fa-plus"></i>
                                                Upload
                                                </file-upload>

                                                <a v-if="item.path" :href=" item.path" target="_blank" class="btn btn-success"><i class="fas fa-file mr-2"></i>Current File</a>

                                                <a type="button" class="btn btn-danger text-white ml-1"  v-if="!$refs[`upload${index}`] || $refs[`upload${index}`][0]?.active" @click.prevent="$refs[`upload${index}`][0].active = false">
                                                <i class="fa fa-stop" aria-hidden="true"></i>
                                                Stop Upload
                                                </a>
                                            </div>
                                            <div class="form-group col-12 text-right">
                                                <input type="submit" class="btn btn-lg btn-current" value="Submit">
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="row p-3">
                    <div class="col-12 mb-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="agree" :id="`check${this.submissionId}`">
                            <label class="form-check-label" for="check">
                                Make sure all participants' answers have been given points correctly. The points that have been filled can be changed at any time
                            </label>
                        </div>
                    </div>
                    <div class="col-12 text-right">
                        <button :disabled="!agree" @click="updateScore()" class="btn btn-lg btn-current"><i class="fas fa-star mr-2"></i>Update Total Score</button>
                    </div>
                </div>
            </div>
        <div v-else>
            <div class="card-body">
                <div class="col-12 text-center py-3">
                    <p> Loading answers </p>
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isLoadComment" class="mt-3 card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">  
            <div class="card-body">
                <h4>Comments</h4>
                <div v-if="comments.length" style="height: calc(63vh - 100px);" class="scrollable col scroll-bar border-0">
                    <div class="row my-1 px-1 message-user" v-for="(item, n) in comments" :key="n">
                        <figure class="avatar p-1">
                            <img :src="item.user.photo ? item.user.photo : '/images/profile.png'" alt="image" style="width: 40px; height: 40px;">
                        </figure>
                        <div class="col pt-1">
                            <h5 class="font-xssss mt-1 float-right">{{item.created_at | formatDate}}</h5>
                            <h3 class="fw-700 mb-0">
                                <p class="font-xsss text-grey-900 text-dark d-block mb-1">{{item.user.name}}</p>
                            </h3>
                            <span class="font-xsss text-grey-700 d-block">{{item.message}}</span>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img :src="'/images/empty.png'" alt="" width="250">
                                <h3 class="text-muted">Comment is Empty!</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="postComment()" class="row justify-content-between p-5 mt-3 message-user">
                    <div class="col-10 m-0 p-0">
                        <input type="text" v-model="message" placeholder="Add a comment...." class="form-control w-100 py-0" required>
                    </div>
                    <div class="col-2 m-0 p-0 pl-lg-3">
                        <button type="submit" class="btn btn-current btn-block btn-lg rounded"><i class="fas fa-paper-plane font-xs"></i></button>
                    </div>
                </form>
            </div>
        </div>
        <div v-else>
            <div class="card-body">
                <div class="col-12 text-center py-3">
                    <p> Loading comments </p>
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import FileUpload from 'vue-upload-component'
export default {
    filters: {
        numfor: function(x) {   
            return parseFloat(x).toFixed(2);
        }
    },
    components:{
        FileUpload
    },
    data(){
        return {
            submissionId: this.$route.params.submissionId,
            slug: this.$route.params.idCourse,
            isLoadAnswer: true,
            isLoadComment: true,
            isLoadDetail: true,
            submissionDetail: {},
            answers: [],
            answer: 0,
            comments: [],
            disButton: false,
            agree: false,
            message: '',
        }
    },
    async created(){
        this.getSubmissionDetail()
        await this.getAnswers()
        this.getComments()
    },
    methods: {
        compareDatesGreater(d1, d2 = null) {
            let date1 = moment(d1).format('YYYY-MM-DD H:m');
            let date2 = moment().format('YYYY-MM-DD H:m');
            if (d2) {
                date2 = new Date(d2).getTime();
            }
            if (date1 > date2) {
                return true;
            } else {
                return false;
            }
        },
        async getSubmissionDetail(){
            this.isLoadDetail = true
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/submission/id?submission_id=${this.submissionId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.submissionDetail = res.data.data
                    console.log('DETAIL', res.data.data)
                    this.isLoadDetail = false
                })
        },
        async updateScore(){
            var data = {
                _method: 'patch',
                slug: this.slug,
                assignment_id: this.submissionDetail.assignment_id,
                id: this.submissionId,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment/submission/update-score`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(async(res) => {
                if (res.data.success){
                    this.$swal({
                        toast: true,
                        title: 'Submission !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    await setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Submission !',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                
            }).catch((err) => console.error(err.response))
        },
        setItemRef(i) {
            return `upload${i}`;
        },
        async fileAction(file) {
            this.postFile(file)
        },
        async postFile(file){
            this.disButton = true
            let data = new FormData();
            data.append('dir', 'submission');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.answers[this.answer].path = res.data.path
                this.disButton = false
                return res.data
            })
        },
        updateFile(value) {
            this.files[this.answer] = value
        },
        setAnswer(i) {
            this.answer = i
        },
        async inputFile(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs[`upload${this.answer}`][0].active) {
                    this.$refs[`upload${this.answer}`][0].active = true
                }
            }
        },
        async postComment(){
            var data = {
                submission_id: this.submissionId,
                message: this.message,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/feedback/comment`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(async (res) => {
                if (res.data.success) {
                    this.getComments()
                    this.$swal({
                        toast: true,
                        title: 'Comment !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.message = ''
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Comment !',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                
            }).catch((err) => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Comment !',
                        text: err.response.data.message[0],
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async getAnswers(){
            this.isLoadAnswer = true
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/submission/answers?submission_id=${this.submissionId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.answers = res.data.data
                    this.setAnswer(0)
                    this.isLoadAnswer = false
                })
        },
        async getComments(){
            this.isLoadComment = true
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/submission/comments?submission_id=${this.submissionId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.comments = res.data.data
                    this.isLoadComment = false
                })
        },
        async postFeedback() {
            var data = {
                answer : this.answers[this.answer]
            }
            console.log("post Feedback", data)
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment/submission/feedback`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(async(res) => {
                if (res.data.success){
                    this.$swal({
                        toast: true,
                        title: 'Submission !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Submission !',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                
            }).catch((err) => console.error(err.response))
        }
    }
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-content{
    max-height: 50vh;
    max-width: 100%;
    overflow: auto;
}

.table {
    min-width: 100wh;
}
::v-deep .ql-editor img {
    width: 100% !important;
}

.divider {
    min-width: 100%;
    height: 1px;
    background-color: black;
    opacity: 50%;
}

.card {
    min-width: 300px
}
</style>